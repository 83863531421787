import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Web3 from "web3";
import abi from "./abi.json";
import MerkleTree from "merkletreejs";
import keccak256 from "keccak256";
import whitelist from "./whitelist.json";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(null);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0xaB9E747DcC1D96B16B6168D721281457839e0b06"
    );

    setInstance(ins);
    getProof();
    getData();
    // if (window.ethereum) {
    //   const getTotalMinted = async () => {
    //     var tokenCount = await ins.methods.totalSupply().call();
    //     console.log(tokenCount);
    //     setTotalMinted(tokenCount);
    //   };
    //   getTotalMinted();
    // }
  }, []);

  const getData = async () => {
    const urlss =
      "https://mainnet.infura.io/v3/79a026f80c934021bb6f847ad6a3b219";

    const web3test = new Web3(urlss);
    const daiToken = new web3test.eth.Contract(
      abi,
      "0xaB9E747DcC1D96B16B6168D721281457839e0b06"
    );
    var tokenCount = await daiToken.methods.totalSupply().call();
    setTotalMinted(tokenCount);
  };

  const getProof = (address) => {
    const addresses = whitelist;
    const leaves = addresses.map((x) => keccak256(x));
    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    const buf2hex = (x) => "0x" + x.toString("hex");
    console.log(buf2hex(tree.getRoot()));
    const leaf = keccak256(address);
    // console.log(buf2hex(leaves[0]));
    const proof = tree.getProof(leaf).map((x) => buf2hex(x.data));
    return proof;
  };

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/mint.brainslum.com/");
      alert("Meta Mask not detected");
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        if (await instance.methods.presaleActive().call()) {
          console.log(
            await instance.methods.presaleMint(mintNumber, getProof(add)).send({
              from: add,
              value: (
                (await instance.methods.presaleCost().call()) * mintNumber
              ).toString(),
            })
          );
        } else if (await instance.methods.publicSaleActive().call()) {
          console.log(
            await instance.methods.mint(mintNumber).send({
              from: add,
              value: (
                (await instance.methods.publicCost().call()) * mintNumber
              ).toString(),
            })
          );
        } else {
          alert("Sale is not active");
        }
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 4) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <>
      <div className="mint-page">
        <Container>
          <div className="navb">
            <div className="d-flex justify-content-center align-items-center">
              <a href="https://www.brainslum.com/" target="_blank">
                <img
                  className="img-fluid logo"
                  src={require("./assets/img/logo2.png").default}
                  alt=""
                />
              </a>
            </div>

            <div className="text-center d-flex justify-content-center align-items-center rmt">
              <a
                href="https://twitter.com/brain_slum"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="si"
                  src={require("./assets/img/Twitter.png").default}
                  alt=""
                />
              </a>
              <a
                href="https://discord.com/invite/pXwcw5NvDa"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="si"
                  src={require("./assets/img/Discord.png").default}
                  alt=""
                />
              </a>
              <a
                href="https://www.instagram.com/brain_slum/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="si"
                  src={require("./assets/img/Instagram.png").default}
                  alt=""
                />
              </a>
              <a
                href="https://opensea.io/collection/brainslum-genesis"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="si"
                  src={require("./assets/img/Opensea.png").default}
                  alt=""
                />
              </a>
            </div>
          </div>
        </Container>
        <div className="pb-5">
          <Container className="d-flex justify-content-center">
            <div className="mint">
              <div className="text-center">
                <p className="subtitle p-0 m-0 text-center  text-uppercase">
                  Presale Mint price 0.045 ETH
                </p>
                <div onClick={requestAccount} className="mt-3 btn cusbtn">
                  <span className="font-weight-bold text-uppercase">
                    {add ? add.substring(0, 15) : "Connect Wallet"}
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <div className="mx-3">
                    <img
                      onClick={minus}
                      className="plus-img bg-danger"
                      src={require("./assets/img/4.png").default}
                      alt=""
                    />
                  </div>
                  <div className="display-number d-flex justify-content-center align-items-center">
                    <span className="number">{mintNumber}</span>
                  </div>
                  <div className="mx-3">
                    <img
                      onClick={plus}
                      className="plus-img bg-success"
                      src={require("./assets/img/2.png").default}
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-center mt-5">
                  <button
                    onClick={mint}
                    className="mintbutton btn  text-uppercase"
                  >
                    Mint Now
                  </button>
                </div>
                <div className="text-center mt-4">
                  <h3 className="font-weight-bold bfont">
                    {totalMinted} / 3333
                    {/* {totalMinted} */}
                  </h3>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default App;
